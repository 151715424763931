"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var locationSubscribers = [];
var triggerLocationChange = function triggerLocationChange(location) {
  return locationSubscribers.forEach(function (s) {
    return s(location);
  });
};
var onLocationChange = function onLocationChange(cb) {
  locationSubscribers.push(cb);
  return function () {
    locationSubscribers = locationSubscribers.filter(function (d) {
      return d !== cb;
    });
  };
};
init();
var _default = onLocationChange;
exports["default"] = _default;
function init() {
  if (typeof document !== 'undefined') {
    var oldPopstate = window.onpopstate;
    window.onpopstate = function () {
      if (oldPopstate) {
        oldPopstate.apply(void 0, arguments);
      }
      triggerLocationChange(window.location);
    };
    ['pushState', 'replaceState'].forEach(function (methodName) {
      var old = window.history[methodName];
      window.history[methodName] = function () {
        setTimeout(function () {
          return triggerLocationChange(window.location);
        }, 0);
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        return old.apply(window.history, args);
      };
    });
  }
}