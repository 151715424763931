"use strict";

/* eslint-disable import/no-dynamic-require */
var plugins = require(process.env.REACT_STATIC_PLUGINS_PATH)["default"];
var _require = require('./browser'),
  registerPlugins = _require.registerPlugins;
registerPlugins(plugins);
if (process.env.NODE_ENV !== 'production' && typeof document !== 'undefined' && module && module.hot) {
  module.hot.accept(process.env.REACT_STATIC_PLUGINS_PATH, function () {
    registerPlugins(require(process.env.REACT_STATIC_PLUGINS_PATH)["default"]);
  });
}