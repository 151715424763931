"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
var _utils = require("../utils");
var _ = require("..");
var _Visibility = _interopRequireDefault(require("../utils/Visibility"));
var usePrefetch = function usePrefetch(path) {
  var ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    if (!ref.current) {
      return;
    }
    (0, _Visibility["default"])(ref.current, function () {
      return (0, _.prefetch)((0, _utils.getRoutePath)(path));
    });
  }, [ref.current, path]);
  return ref;
};
var _default = usePrefetch;
exports["default"] = _default;