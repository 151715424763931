"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _axios = _interopRequireDefault(require("axios"));
var _2 = require("..");
var _useStaticInfo = require("./useStaticInfo");

//
// This will likely become a react cache resource soon
var siteDataPromise;
var siteDataReady;
var siteData;
var useSiteData = function useSiteData() {
  // When clientData reloads, rerender components
  // that use this hook
  // eslint-disable-next-line
  var _useState = (0, _react.useState)(0),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    _ = _useState2[0],
    setCount = _useState2[1];
  (0, _react.useEffect)(function () {
    return (0, _2.onReloadClientData)(function () {
      siteDataPromise = null;
      siteDataReady = false;
      setCount(function (old) {
        return old + 1;
      });
    });
  });
  var staticInfo = (0, _useStaticInfo.useStaticInfo)();
  if (staticInfo) {
    return staticInfo.siteData;
  }
  if (siteDataReady) {
    return siteData;
  }
  if (!siteDataPromise) {
    // Request the site data
    siteDataPromise = _axios["default"].get('/__react-static__/siteData').then(function (_ref) {
      var data = _ref.data;
      siteDataReady = true;
      siteData = data;
    });
  } // Throw the promise

  throw siteDataPromise;
};
var _default = useSiteData;
exports["default"] = _default;