"use strict";

/* eslint-disable import/no-dynamic-require */
var _require = require('./browser'),
  registerTemplates = _require.registerTemplates;
var _require2 = require(process.env.REACT_STATIC_TEMPLATES_PATH),
  templates = _require2["default"],
  notFoundTemplate = _require2.notFoundTemplate;
registerTemplates(templates, notFoundTemplate);
if (process.env.NODE_ENV !== 'production' && typeof document !== 'undefined' && module && module.hot) {
  module.hot.accept(process.env.REACT_STATIC_TEMPLATES_PATH, function () {
    var _require3 = require(process.env.REACT_STATIC_TEMPLATES_PATH),
      templates = _require3["default"],
      notFoundTemplate = _require3.notFoundTemplate;
    registerTemplates(templates, notFoundTemplate);
  });
}